
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import UpdateUi from "@/components/Core/Update/UpdateUI.vue";

import { GenerationInfos } from "@/domain";

import { IGenerationService } from "@/services";

@Component({
  components: {
    UpdateUi,
  },
})
export default class GenerationUpdate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  item!: GenerationInfos;

  @Prop({ required: true })
  forbiddenNames!: string[];

  get generationService() {
    return this.container.resolve<IGenerationService>(S.GENERATION_SERVICE);
  }

  loading: boolean = false;
  name: string | null = null;

  mounted() {
    this.name = this.item.name;
  }

  isAllowed(name: string) {
    return (
      _.findIndex(
        this.forbiddenNames,
        (v) => v.toUpperCase() == name.toUpperCase()
      ) != -1 && this.item.name.toUpperCase() != name.toUpperCase()
    );
  }

  async update() {
    try {
      this.loading = true;
      const generationUpdated = await this.generationService.update(
        this.item.id,
        {
          name: this.name!,
          disabled: this.item.disabled,
        }
      );
      this.$emit("update", generationUpdated);
    } finally {
      this.loading = false;
    }
  }
}
